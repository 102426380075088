<template>
  <div class="formdata">
    <el-form ref="form" :model="form" label-width="150px">
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <!-- 监管 -->
            <el-form-item label="执业证号:">
              <el-input
                v-model="form.pracNo"
                placeholder="请输入执业证号"
                :disabled="isEditor"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证号:">
              <el-input
                v-model="form.certNo"
                placeholder="请输入资格证号"
                :disabled="isEditor"
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="职称证号:">
              <el-input
                v-model="form.titleNo"
                placeholder="请输入职称证号"
                :disabled="isEditor"
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="医师执业级别:">
              <el-select v-model="form.pracLevel" :disabled="isEditor">
                <el-option
                  v-for="item in doctorPracticeLevelList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="是否同意以上条款:">
              <el-select v-model="form.agreeTerms" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="签约医疗机构名称:">
              <el-select v-model="form.orgCode" :disabled="isEditor">
                <el-option
                  v-for="item in firstPracticeOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期开始时间:">
              <el-date-picker
                v-model="form.docMultiSitedDateStart"
                :disabled="isEditor"
                type="date"
                style="width: 90%"
                placeholder="选择开始日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="申请拟执业医疗机构意见:">
              <el-input
                v-model="form.hosOpinion"
                :disabled="isEditor"
                placeholder="请输入申请拟执业医疗机构意见                                                                                     "
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医院是否已确认:">
              <el-select v-model="form.hosConfirmFlag" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="性别:">
              <el-select v-model="form.geCode" :disabled="isEditor">
                <el-option
                  v-for="item in sexList"
                  :key="item.geCode"
                  :label="item.orgName"
                  :value="item.geCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="出生日期:">
              <el-date-picker
                v-model="form.docBirthdate"
                :disabled="isEditor"
                type="date"
                style="width: 90%"
                placeholder="选择出生日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="学历:">
              <el-select v-model="form.docEdu" :disabled="isEditor">
                <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="行政职务:">
              <el-input
                v-model="form.docPosition"
                :disabled="isEditor"
                placeholder="请输入行政职务"
              />
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="执业证发证日期:">
              <el-date-picker
                v-model="form.pracRecDate"
                :disabled="isEditor"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证发证日期:">
              <el-date-picker
                v-model="form.certRecDate"
                :disabled="isEditor"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="职称证取得时间:">
              <el-date-picker
                v-model="form.titleRecDate"
                type="date"
                :disabled="isEditor"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师执业类别:">
              <el-input
                v-model="form.pracType"
                placeholder="请输入执业类别"
                :disabled="isEditor"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师执业范围:">
              <el-input
                v-model="form.pracScopeApproval"
                :disabled="isEditor"
                placeholder="请输入医师执业范围,所有执业范围后缀加上“专业”"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南是否已备案:">
              <el-select v-model="form.recordFlag" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期结束时间:">
              <el-date-picker
                v-model="form.docMultiSitedDateEnd"
                :disabled="isEditor"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南处方开具权是否备案:">
              <el-select v-model="form.presRecordFlag" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="民族:">
              <el-select v-model="form.nationCode" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址:">
              <el-select
                v-model="form.provinceCode"
                :disabled="isEditor"
                style="width: 40%"
              >
                <el-option
                  v-for="item in districtList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
              <el-select
                v-model="form.cityCode"
                :disabled="isEditor"
                style="width: 40%"
              >
                <el-option
                  v-for="item in isNoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址邮编:">
              <el-input
                v-model="form.docPostalCode"
                :disabled="isEditor"
                placeholder="请输入家庭地址邮编"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="健康状况:">
              <el-select v-model="form.healthCondition" :disabled="isEditor">
                <el-option
                  v-for="item in healthConditionList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="最近两周期医师定期考核是否合格:">
              <el-select v-model="form.qualifyOrNot" :disabled="isEditor">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
      <div style="margin-left: 30px">
        <el-form-item label="业务水平考核机构或组织名称、考核培训时间及结果:">
          <el-input
            v-model="form.appraisalContent"
            :disabled="isEditor"
            placeholder="请输入考核培训时间及结果"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="何时何地因何种原因受过何种处罚或处分:">
          <el-input
            v-model="form.punishContent"
            :disabled="isEditor"
            placeholder="请输入何种处罚或处分"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="其它要说明的问题:">
          <el-input
            v-model="form.otherContent"
            :disabled="isEditor"
            placeholder="请输入其他问题"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  doctorPracticeLevelList,
  isNoList,
  sexList,
  healthConditionList,
  educationList,
} from '@/utils/component/publicList';
export default {
  name: 'Supervision',
  props: {
    supervisionList: {
      type: Object,
      default() {
        return {};
      },
    },
    isEditor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      doctorPracticeLevelList: doctorPracticeLevelList, // 医生执业级别
      isNoList: isNoList, // 是否同意条款协议
      healthConditionList: healthConditionList, // 健康列表
      educationList: educationList, // 学历列表
      sexList: sexList, // 性别字典
      districtList: [], // 行政区列表
      nationList: [], // 民族列表
      form: {
        agreeTerms: '',
        pracNo: '',
        certNo: '', // 资格证号
        titleNo: '', // 职称证号
        pracLevel: '', // 医师执业级别
        orgName: '',
        orgCode: '',
        appraisalContent: '',
        docMultiSitedDateStart: '',
        hosOpinion: '',
        geCode: '',
        geName: '',
        docBirthdate: '',
        docEdu: '',
        docPosition: '',
        pracRecDate: '',
        certRecDate: '',
        titleRecDate: '',
        pracType: '',
        qualifyOrNot: '',
        pracScopeApproval: '',
        docMultiSitedDateEnd: '',
        presRecordFlag: '',
        recordFlag: '',
        nationCode: '',
        docPostalCode: '',
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        healthCondition: '',
        punishContent: '',
        otherContent: '',
      },
    };
  },
  computed: {
    ...mapState('doctorTeam', {
      firstPracticeOrganList: (state) => state.firstPracticeOrganList,
    }),
  },
  watch: {
    'form.orgCode': function (value) {
      this.firstPracticeOrganList.forEach((item) => {
        if (item.orgCode === value) {
          this.form.orgName = item.orgName;
        }
      });
    },
    'form.geCode': function (value) {
      this.sexList.forEach((item) => {
        if (item.geCode === value) {
          this.form.geName = item.geName;
        }
      });
    },
    'form.nationCode': function (value) {
      this.nationList.forEach((item) => {
        if (item.a === value) {
          this.form.nationName = item;
        }
      });
    },
    'form.provinceCode': function (value) {
      this.districtList.forEach((item) => {
        if (value === item.b) {
          this.form.provinceName = item.name;
        }
      });
    },
    'form.cityCode': function (value) {
      console.log(value);
    },
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.supervisionList));
    console.log(this.form);
    this.getPracticeOrganList();
    this.getNationList();
  },
  methods: {
    ...mapActions('doctorManage', ['regulatoryOrganList']),
    ...mapActions('basicDictionary', ['dicQueryList']),
    // 查询相应的字典列表
    // 民族
    getNationList() {
      this.dicQueryList({ dicTypeCode: 'Nationality_01' }).then((res) => {
        this.nationList = res.data;
      });
    },
    // 行政区划
    getAdministrativeList() {
      this.dicQueryList({ dicTypeCode: 'District_01 ' }).then((res) => {
        this.districtList = res.data;
      });
    },
    getPracticeOrganList() {
      this.regulatoryOrganList({});
    },
  },
};
</script>
<style lang="scss" scoped></style>
